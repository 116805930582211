import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/share_form_pafd_choices',
  access: 'shareFormPafdChoice',
  resource: {
    definition: '#ShareFormPafdChoice',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
        },
      },
      signatures: {
        description: 'Sygnatury (po przecinku)',
        type: FIELD_TYPES.TEXTAREA,
      },
    },
  },
}

export default schema
