import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/share_form_promotion_categories',
  access: 'shareFormPromotionCategory',
  resource: {
    definition: '#ShareFormPromotionCategory',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
        },
      },
    },
  },
}

export default schema
